/* privacy-policy.css */
.policy {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;

    h2 {
        margin-bottom: 10px;
        font-size: 1.5em;
        font-weight: bold;
    }

    h3 {
        margin-top: 20px;
        margin-bottom: 5px;
        font-size: 1.2em;
        font-weight: bold;
    }

    ul {
        padding-left: 20px;
    }

    ul li {
        margin-bottom: 10px;
        list-style: disc;
    }

    p {
        margin-bottom: 15px;
    }

    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
}

